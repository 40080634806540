import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

// Components
import SEO from "../components/SEO"
import HeroMedia from "../modules/HeroMedia/HeroMedia"
import TextIcon from "../modules/TextIcon/TextIcon"
import TextImage from "../modules/TextImage/TextImage"
import NewsletterForm from "../modules/NewsletterForm/NewsletterForm"
import IconTabs from "../modules/IconTabs/IconTabs"

// Helpers & mock content
import { fireEvent } from "../utils/helpers"
import { landingContent } from "../utils/content/landingContent"

// markup
const IndexPage = () => {
  const { formatMessage } = useIntl()
  const { seo, mainHero, textImage, textIconTwo, textIcon, iconTabs, newsletterForm } = landingContent

  useEffect(() => {
    fireEvent("nav-scheme", "light")
  }, [])

  return (
    <div className="relative overflow-hidden">
      <SEO title={formatMessage({ id: seo.metaTitle })} description={formatMessage({ id: seo.metaDescription })} noindex />
      <HeroMedia content={mainHero} />
      <TextIcon content={textIcon} />
      <TextIcon content={textIconTwo} />
      <TextImage content={textImage} />
      <IconTabs content={iconTabs} />
      <NewsletterForm content={newsletterForm} />
    </div>
  )
}

export default IndexPage
